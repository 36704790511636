import config from '../utils/env';

interface UrlConstants {
  mynib: string;
  careers: string;
  newsAndMedia: string;
  getQuickQuote: (promoCode?: string) => string;
  getQuoteWidgetUrl: string;
  cnJoin: string;
  contactUs: string;
  appleAppStore: string;
  googlePlayStore: string;
  nwoPortal: string;
  findAProvider: string;
  employerInsurance: string;
  employerInsuranceCallbackSection: string;
  travelInsurance: string;
  compareLifeAndLivingInsurance: string;
  lifeAndLivingInsurance: string;
  aboutLifeLivingInsurance: string;
  lifeLivingInsuranceContactUs: string;
  currentOfferLifeLivingInsurance: string;
  estimateYourCoverageLifeLivingInsurance: string;
  whyLifeLivingInsuranceCoverIsImportant: string;
  howToApplyLifeLivingInsurance: string;
  lifeAndLivingInsuranceWording: string;
  lifeAndLivingInsuranceFactSheet: string;
  lifeLivingHomeowners: string;
  temporaryAccidentalDeathCoverPDF: string;
  nonPharmac: string;
  bluesShareADay: string;
  bluesRugby: string;
  foundationContactForm: string;
  foundation: string;
  foundationProjectClearhead: string;
  foundationUniversityOfOtago: string;
  foundationPreventionPartnership: string;
  ngatiWhatuaOrakei: string;
  tenehenehenui: string;
  financialStrength: string;
}

const LIFE_COVER_OPTIONS_PATH = '/life-cover-options';

export const urlConstants: UrlConstants = {
  mynib: `${config.portalUrl}`,
  careers: 'https://careers.nib.co.nz',
  newsAndMedia: '/news-and-media/news-details/free-health-and-dental-check-ups',
  getQuickQuote: (promoCode) =>
    promoCode ? `${config.dtcJoinUrl}?promoCode=${promoCode}` : `${config.dtcJoinUrl}`,
  getQuoteWidgetUrl: `${config.dtcJoinUrl}`,
  cnJoin: `${config.dtcJoinUrl}?locale=zh`,
  contactUs: 'https://health.nib.co.nz/contact-us',
  appleAppStore: 'https://apps.apple.com/us/app/my-nib-nz/id968982318',
  googlePlayStore: 'https://play.google.com/store/apps/details?id=nz.co.nib.mynib&hl=en',
  nwoPortal: 'https://www.health-iq.com.au/Portal/Member/login/nwo',
  findAProvider: '/find-a-provider',
  employerInsurance: 'https://health.nib.co.nz/business',
  employerInsuranceCallbackSection: 'https://health.nib.co.nz/business#callback_section_panel',
  travelInsurance: 'https://www.nibtravel.co.nz/',
  compareLifeAndLivingInsurance: '/life-cover-options',
  aboutLifeLivingInsurance: `${LIFE_COVER_OPTIONS_PATH}/about-life-living-insurance`,
  currentOfferLifeLivingInsurance: `${LIFE_COVER_OPTIONS_PATH}/special-offers`,
  estimateYourCoverageLifeLivingInsurance: `${LIFE_COVER_OPTIONS_PATH}/estimate-your-cover`,
  whyLifeLivingInsuranceCoverIsImportant: `${LIFE_COVER_OPTIONS_PATH}/why-life-living-cover-is-important`,
  howToApplyLifeLivingInsurance: `${LIFE_COVER_OPTIONS_PATH}/how-to-apply`,
  lifeLivingInsuranceContactUs: `${LIFE_COVER_OPTIONS_PATH}/contact-us`,
  lifeLivingHomeowners: `${LIFE_COVER_OPTIONS_PATH}/for-homeowners`,
  lifeAndLivingInsuranceWording:
    'https://assets.ctfassets.net/ja9v5o5o08yv/pLH4KfjIcr2gJ5I8GufTm/f4298598f74a1b027e5de9abfbea966b/Life_and_Living_Insurance_Cover_Wording_0623.pdf',
  lifeAndLivingInsuranceFactSheet:
    'https://assets.ctfassets.net/ja9v5o5o08yv/105PXD3LGZFWPdbvtoQ16h/79f5a9b0f3daa64c407124fe07028597/Life_and_Living_Insurance_Fact_Sheet_0623.pdf',
  lifeAndLivingInsurance: 'https://health.nib.co.nz/life-and-living-insurance',
  temporaryAccidentalDeathCoverPDF:
    'https://assets.ctfassets.net/ja9v5o5o08yv/43muUAabCb3p1U36ZRTbgt/e2b7ae66255187e6e50d52e7e61c4b15/Temporary_Accidental_Death_Cover_0623.pdf',
  nonPharmac: '/non-pharmac',
  bluesShareADay:
    '/news-and-media/news-details/nib-blues-share-a-day-in-the-life-as-a-professional-womens-rugby-player/',
  bluesRugby: 'https://www.blues.rugby/',
  foundationContactForm: 'https://www.nib.com.au/foundation/contact-us',
  foundation: 'https://www.nib.com.au/foundation/',
  foundationProjectClearhead: 'https://www.nib.com.au/foundation/project/clearhead-nz',
  foundationUniversityOfOtago: 'https://www.nib.com.au/foundation/project/university-of-otago-nz',
  foundationPreventionPartnership:
    'https://www.nib.com.au/foundation/prevention-partnerships-program',
  ngatiWhatuaOrakei: '/ngatiwhatuaorakei',
  tenehenehenui: '/tenehenehenui',
  financialStrength: '/about-nib/financial-advice#financial-strength-nibnz',
};
